<template>
    <div>
        <div class="text-center">
            <h2>Ioannis Karavidas</h2>
        </div>
        <v-container class="main-text text-container">
            <div>
                <v-img :width="415" :height="415" style="float: left;" class="bradius" :src="require('@/assets/partners/Photo-I-Karavidas.jpg')" />
                <p class="cv-text">
                    Dr. Ioannis Karavidas holds an integrated master's degree in Agricultural Biotechnology and a Ph.D. from the Laboratory of Vegetable Production (LVP) at the Agricultural University of Athens (AUA). Since the beginning of 2023, he has been serving as a Post-Doctorate Researcher at the LVP of the Department of Crop Science at AUA. His research focuses on plant water and nutrient use efficiency, sustainable agronomic practices, and plant responses to abiotic stress factors. Dr. Karavidas has authored 11 papers published in international peer-reviewed journals with Impact Factors in the Journal Citation Reports. Additionally, he has contributed to 12 scientific papers in full proceedings of international conferences and over 35 papers in conference proceedings. To date, he has been involved as a researcher in 3 European (TRUE, RADIANT, ECONUTRI), 3 national (NUTRISENSE, BIOPRASARMOGI, OPTIMUS), and 2 private research projects (IGC-Green, BIOSPRIM).
                </p>
            </div>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.cv-card {
    border: 1px solid #D9D9D9;
    border-radius: 25px;
    text-align: center;
}
.bradius {
    border-radius: 25px;
    margin-right: 15px;
}
.cv-text {
    display: inline;
}
.main-text {
    width: 60vw;
    margin: auto;
    color: #646464;
}
</style>
